.mainContainer {
    width: 100%;
    overflow-x: hidden;
}

.backgroundBox {
    width: 95%;
    height: 200vh;
    position:relative;
    background-color: rgb(249,247,242);
    margin-right: auto;
    z-index: -1;
    padding-top: 1rem;
    margin-bottom: 2rem;
}

.parallaxContainer {
    width: 100vw;
    height: 80vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-image:url('../../assets/home/start.JPG'); /* Helyettesítsd a saját képed URL-jével */
    background-size: cover; /* A kép kitölti a konténert */
    background-position: center;
    background-attachment: fixed; /* Ez adja a parallax hatást */
    background-repeat: no-repeat;
    will-change: background-position; /* Teljesítményoptimalizálás */
    margin-top: 1rem;
}

.intro {
    font-size: 2rem;
    font-weight: 600;
    font-family: 'Cinzel', serif;
    letter-spacing: 1px;
    color: rgb(140,120,80);
    text-shadow: -1px -2px #000, 1px -2px #000, -1px 1px #ffa, 1px 1px #ffa;
}

@media (max-width: 800px) {
    .parallaxContainer {
        background-image:url('../../assets/home/start_mobile.JPG');
        background-attachment: scroll;
    }
}


/*----------------TITLES------------------*/


.titleBox {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-top: 4rem;
    margin-bottom: 14rem;
}

.intro2 {
    font-family: 'Cinzel', serif;
    font-size: 2.5rem;
    letter-spacing: 0.1px;
    margin-top:4rem;
    padding: 10px;
}

.intro3 {
    font-family: "Sacramento", cursive;
    font-size: 2rem;
    width: 30%;
    text-align: center;
}



@media (max-width: 1100px) {
    .intro3 {
        width: 60%;
    }
}

@media (max-width: 800px) {
    .titleBox {
        margin-bottom: 5rem;
    }

    .intro3 {
        width: 70%;
    }
}

/*----------- ABOUT CONTAINER ------------*/

.aboutContainer {
    width: 70%;
    height: 30%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: rgb(247,243,237);
    margin-left: 2rem;
    margin-right: auto;
    margin-top: 7rem;
    z-index: 1;
}

.aboutTextContainer {
    display: flex;
    width: 85%;
    flex-direction: column;
    align-items: flex-start;
    text-align: justify;
    margin: 1rem;
    padding-right: 4rem;
}

.text {
    padding: 10px;
    font-size: 1rem;
    font-family: "Playfair Display", serif;
    letter-spacing: 0.3px;
}

.aboutImageContainer {
    position: absolute;
    right: 15%;
    top: 140%;
}

.profilePicture {
    width:350px;
    z-index: 3;
    border: 10px solid rgb(247,243,237);
}

@media (max-width: 1600px) {
    .aboutImageContainer {
        top: 135%;
        right: 7%;
    }
}

@media (max-width: 1400px) {
    .aboutImageContainer {
        top: 170%;
        right: 7%;
    }
}

@media (max-width: 1100px) {
    .aboutContainer {
        height: 50vh;
    }

    .aboutImageContainer {
        top: 160%;
        right: 4%;
    }
}

@media (max-width: 1000px) {
    .aboutContainer {
        height: 35vh;
    }

    .aboutImageContainer {
        top: 125%;
    }
}

@media (max-width: 1000px) {
    
    .aboutContainer {
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        text-align: center;
        align-items: center;
        margin: 0;
        padding: 0;
    }

    .aboutTextContainer {
        width: 100%;
        display: flex;
        flex-direction: column;
        padding-right: 3rem;
        padding-left: 1rem;
        align-items: center;
        text-align: center;
        hyphens: auto;
        word-wrap: break-word;
        overflow-wrap: break-word;
        white-space: normal;
    }

    .aboutImageContainer {
        position: sticky;
        top: 0;
        right: 0;
    }

    .profilePicture {
        width: 270px;
        margin: 0.75rem;
    }
}

/*------- FURTHER TEXTS -------*/

.container {
    width: 100%;
    display: flex;
    margin-top: 4rem;
}

.imageBox {
    display: flex;
    flex-direction: column;
    width: 40%;
    align-items: center;
    justify-content: center;
}

.image {
    width: 300px;
    margin-bottom: auto;
}

.textBox {
    display: flex;
    flex-direction: column;
    width: 65%;
    padding: 2rem;
    border: 40px solid rgb(247,243,237);
}

.title {
    font-family: "Sacramento", cursive;
    font-size: 2.5rem;
    margin-top: 2rem;
}

.text {
    line-height: 1.5;
    margin-left: 2rem;
    text-align: justify;
}

@media (max-width: 800px) {
    .container {
        width: 100%;
        flex-direction: column;
    }

    .textBox {
        width: 100%;
        border: 10px solid rgb(247,243,237);
    }

    .imageBox {
        display: none;
    }
}

/*---------------ORDER CONTAINER---------------*/

.orderContainer {
    width: 100%;
    background: linear-gradient(to bottom, transparent 10%, rgb(247,243,237) 90%);
    height: auto;
}

/*--------------IMAGES-------------*/

.imageContainer {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 3px;
    margin-top: 5rem;
    margin-left: 3rem;
    margin-right: 3rem;
    margin-bottom: 5rem;
}

.images {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

/*------------------ORDER--------------------*/

.orderBox {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding-bottom: 5rem;
}

.orderTitle {
    font-family: 'Cinzel', serif;
    font-size: 2.5rem;
    letter-spacing: 0.1px;
}

.orderTextBox {
    display: flex;
    justify-content: space-around;
    font-family: "Playfair Display", serif;
    gap: 3rem;
    align-items: center;
    padding: 1rem;
    margin-top: 6rem;
    width: 90%;
}

.orderSubTitle {
    font-family: "Sacramento", cursive;
    font-size: 2rem;
    text-align: center;
}

.buttonBox {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 3rem;
}

@media (max-width: 800px) {
    .orderTextBox { 
        flex-direction: column;
        margin-top: 2rem;
    }
}

@media (max-width: 400px) {
    
}

