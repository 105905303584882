.container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
    gap: 3px;
    margin: 3rem;
}

.img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    cursor: pointer; /* Mutatja, hogy kattintható */
    transition: filter 0.3s ease, transform 0.3s ease;
}

.large {
    grid-column: span 2; /* A kép két oszlopot foglal el */
    grid-row: span 2; /* A kép két sort foglal el */
}

.img:hover {
    filter: brightness(70%);
}

/* Lightbox overlay */
.lightbox {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.8); /* Sötét háttér */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* Mindent felülír */
    cursor: pointer;
}

/* Lightboxban megjelenített nagy kép */
.lightboxImage {
    max-width: 95%;
    max-height: 95%;
}

/* Bezárás gomb */
.closeButton {
    position: absolute;
    top: 20px;
    right: 20px;
    background: none;
    border: none;
    color: white;
    font-size: 30px;
    font-weight: bold;
    cursor: pointer;
    z-index: 1001;
}

@media (max-width: 1600px) {
    .container {
        grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
        gap: 3px;
    }
}

@media (max-width: 1300px) {
    .container {
        grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
        gap: 3px;
    }
}


@media (max-width: 1100px) {
    .container {
        grid-template-columns: repeat(auto-fit, minmax(75px, 1fr));
        gap: 2px;
        margin: 2rem;
    }

    .lightboxImage {
        max-width: 90%;
        max-height: 90%;
    }

    .closeButton {
        top: 10px;
        right: 10px;
        font-size: 24px;
    }
}
