.button {
    margin-bottom: 2.5rem;
    margin-top: 1.5rem;
    padding: 1rem 2rem;
    font-size: 13px;
    font-weight: bold;
    font-family: 'Cinzel', serif;
    letter-spacing: 2px;
    color:black;
    border-color: rgb(237,224,210);
    background-color: white;
    cursor: pointer;
}

.button:hover {
    background-color: #e2ddd9; /* Halványan sötétebb bézs hoverre */
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2); /* Erősebb árnyék hoverre */
}

.button:focus {
    outline: none; /* Kijelölés keret eltávolítása */
    border: 1px solid #000; /* Opcionális: keret fókuszban */
}