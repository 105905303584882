.applyWorkshopContainer {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: 'Playfair Display', sans-serif;
  letter-spacing: -1px;
}

.titleContainer {
  width: 80%;
  align-items: center;
  text-align: center;
}

.title {
  font-size: 30px;
  margin-bottom: 2rem;
  margin-top: 2rem;
  font-family: 'Cinzel', serif;
  align-items: center;
  text-align: center;
}

.contactWorkshopForm {
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.name, .email, .msg, .phoneNumber {
  width: 100%;
  margin: 0.3rem;
  padding: 0.3rem 1rem;
  font-size: 0.9rem;
  font-family: 'Poppins', sans-serif;
  border: none;
  border-radius: 0.5rem;
  color: black;
  background: white;
}

.msg {
  height: 10rem;
}

.submitBtn {
  width: 10vw;
  margin-top: 1rem;
  padding: 0.5rem 1rem 0.5rem 1rem;
  font-size: 13px;
  font-weight: bold;
  font-family: 'Cinzel', serif;
  letter-spacing: 2px;
  color:black;
  border-color: rgb(237,224,210);
  background-color: white;
  cursor: pointer;
}

@media (max-width: 1300px) {

}

@media (max-width: 1100px) {
  .applyWorkshopContainer {
    height: 70vh;
  }
}


@media (max-width: 800px) {
  .contactWorkshopForm {
    width: 90%;
  }

  .titleContainer {
    width: 100%;
  }

  .submitBtn {
    width: 50%;
  }

  .title {
    font-size: 25px;}
}