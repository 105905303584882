.mainContainer {
  width: 100%;
}

.animationContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: white;
  overflow: hidden;
}
  
.contentContainer {
  opacity: 1;
}

.logo {
  width: 120px;
  height: 120px;
  position: absolute;
  animation: sizeAndMove 4s forwards;
}

.logoStatic {
  width: 120px;
  height: 120px;
  position: absolute;

}

.logoContainer {
  position: relative;
  z-index: 10;
  top: -15px;
}

@keyframes sizeAndMove {
  0% {
    transform: scale(0.5);
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(0.5);
    animation-timing-function: ease-in;
  }
  15% {
    transform: translate(-50%, -50%) scale(1.5);
    animation-timing-function: ease-out;
  }
  50% {
    transform: translate(-50%, -50%) scale(1);
  }
  95% {
    transform: translate(-50%, -50%) scale(1);
    top: 50%;
    left: 50%;
    animation-timing-function: ease-out;
  }
  100% {
    transform: scale(1);
    top: 1%;
    left: 1%;
    transform: translate(0, 0);
  }
}

