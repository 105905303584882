.scrollToTopButton {
    position: fixed;
    bottom: 20px; /* Az oldal aljától való távolság */
    right: 20px; /* Az oldal szélétől való távolság */
    background-color: #e5e6e8; /* Gomb színe */
    color: black;
    border: black 2px solid;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    font-size: 20px;
    font-weight: bold;
    cursor: pointer;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Árnyék */
    transition: opacity 0.3s ease-in-out, transform 0.3s ease; /* Simább megjelenés */
    opacity: 0.8; /* Átlátszóság */
    z-index: 10;
}

.scrollToTopButton:hover {
    opacity: 1; /* Hover állapot */
    transform: scale(1.1); /* Kicsit nagyobb hover esetén */
}

.scrollToTopButton:active {
    transform: scale(0.95); /* Kicsinyítés kattintáskor */
}
