.header {
    width: 100%;
    height: 10vh;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: rgb(249, 247, 242); /* Háttérszín a navigációhoz */
    font-size: 17px; /* Finomabb betűméret */
    font-family: 'Cinzel', sans-serif;
    font-weight: bolder;
    padding: 0 8rem; /* Jobb és bal oldalon margók */
    letter-spacing: -1px;
    margin-top:1rem;
}

.desktopMenu {
    display: flex;
    gap: 1.5rem; /* Navigációs elemek közötti távolság */
}

.list {
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
}

.list li {
    padding: 0.5rem 1rem; /* Csökkentett padding */
}

.list li a {
    text-decoration: none;
    color: #333; /* Szöveg színe */
    font-weight: 500; /* Félkövér, de nem túl erős */
    transition: color 0.3s ease;
}

.list li a:hover {
    color: #555; /* Színváltás hover állapotban */
}

.active {
    border-bottom: 2px solid #333; /* Alsó vonal az aktív linkhez */
}

.mobMenu {
    display: none;
    object-fit: cover;
    height: 1.8rem;
    margin-left: auto; /* Hozzáadja az automatikus jobbra igazítást */
    cursor: pointer;
}

.navMenu {
    display: flex;
    flex-direction: column;
    position: absolute;
    z-index: 2;
    min-width: 15rem;
    height:fit-content;
    top: 4rem;
    right: 2rem;
    padding: 0.5rem;
    border-radius: 2rem;
    background-color: rgb(249, 247, 242);
    border: 1px solid black;
}

.listItem {
    margin: 0.25rem;
    padding: 0.5rem 3rem;
    font-size: 20px;
    color: black;
    cursor: pointer;
}
@media screen and (max-width:1400px) {
    .header {
        height: 12vh;
    }
}

@media screen and (max-width:1000px) {
    .header {
        padding: 0;
        height: 8vh;
    }

    .desktopMenu {
        display: none;
    }

    .mobMenu {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: flex-end;
        margin-right: 2.5rem;
    }

    .navMenu {
        right: 0;
    }

}

@media screen and (max-width:800px) {
    .header {
        padding: 0;
        height: 10vh;
    }
}