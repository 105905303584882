.container {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: row;
    margin-top: 2rem;
    margin-bottom: 2rem;
    background-color: rgb(232, 230, 219);
}

.titleContainer {
    width: 90%;
}

.imageContainer {
    width: 40%;
    display: flex;
    justify-content: flex-start;
    margin: 0 auto; /* Vízszintesen középre igazítja az elemet */
}

.imageContainer img {
    width: 100%;
    height: 100%;
    object-fit: cover; /* a kép szépen arányosan illeszkedik a konténerhez */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    /*clip-path: polygon(0 0, 90% 0, 65% 100%, 0 100%);*/
}

.contactFormContainer {
    width: 60%;
    padding: 5rem;
}



@media (max-width: 1300px) {
    .imageContainer {
        width: 50%;
    }
}

@media (max-width: 1100px) {
    .container {
        height: 80vh;
    }

    .imageContainer {
        display: none;
    }

    .contactFormContainer {
        width: 100%;
        padding: 1rem;
    }
}
