.footer {
    max-width: 100%;
    background-color: rgb(249, 247, 242); /* Világosszürke háttér */
    color: rgb(167,163,143);
    text-align: center;
    font-family: 'Poppins', sans-serif;
}

.footerUpper {
    display: flex;
    flex-wrap: wrap; /* Engedi, hogy az elemek új sorba kerüljenek */
    justify-content: space-around;
    align-items: center;
    padding: 1rem;
    gap: 2rem; /* Közöttük lévő távolság */
}

.footerSection {
    text-align: center;
}

.footerLabel {
    color: rgb(167,163,143);
    margin-bottom: 0.5rem;
}

.footerInfo {
    color: rgb(167,163,143);
    font-size: 1rem;
}

.footerSocial {
    display: flex;
    gap: 10px;
    margin-left:1rem;
}

.footerSocial a {
    color: rgb(167,163,143);
    text-decoration: none;
    
}

.link {
    width: 30px; /* Állítsd be a kívánt szélességet */
    height: 30px; /* Állítsd be a kívánt magasságot */
}

.footerDown {
    width: 100%;
    padding: 1rem;
    background-color: rgb(58,49,49);
    color: #f5f5f5;
    text-align: center;
    font-size: 0.9rem;
    position: relative;
    bottom: 0;
}

.contentText {
    color: rgb(167,163,143);
}

@media (max-width: 800px) {
    .footerUpper {
        justify-content: space-between; /* Igazítsa az elemeket kisebb képernyőn */
    }

    .footerSection {
        flex: 1 1 calc(50% - 1rem); /* Az első kettő egy sorban */
    }

    .footerSection:last-child {
        flex: 1 1 100%; /* Az utolsó elem új sorba kerül */
    }

    .footerSocial {
        justify-content: center;
        margin-top: 0.5rem;
    }
}