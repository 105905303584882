.workshop {
    min-height: calc(100vh - 4rem);
    width: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin: 0 auto;
    font-family: 'Poppins', serif;
    font-size: 1rem;
}
/*-----------TITLE-------------*/

.titleContainer {
    width: 100vw;
    height: 80vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-image:url('../../assets/workshop/start.jpg'); /* Helyettesítsd a saját képed URL-jével */
    background-size: cover; /* A kép kitölti a konténert */
    background-position: center;
    background-attachment: fixed; /* Ez adja a parallax hatást */
    background-repeat: no-repeat;
    will-change: background-position; /* Teljesítményoptimalizálás */
    margin-top: 1rem;
}

.workshopTitle {
    font-size: 40px;
    font-weight: 600;
    font-family: 'Cinzel', serif;
    letter-spacing: 1px;
    color: rgb(140,120,80);
    text-shadow: -1px -2px #000, 1px -2px #000, -1px 1px #ffa, 1px 1px #ffa;
}

@media (max-width: 1200px) {
    .titleContainer {
        height: 30vh;
    }
}

@media (max-width: 1100px) {
    .titleContainer {
        height: 50vh;
    }
}

@media (max-width: 800px) {
    .titleContainer {
        background-image:url('../../assets/workshop/start_mobile.jpg');
        background-attachment: scroll;
    }
}


/*----------FIRST CONTAINER----------*/

.firstContainer {
    display: flex;
    flex-direction: row;
    width: 80%;
    align-items: center;
    margin-top: 3rem;
    margin-bottom: 3rem;
}

.firstContainerText {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: justify;
    margin-right: 4rem;
}

.textT {
    font-size: 19px;
}

.text {
    padding: 10px;
}

.firstContainerImage {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 2rem;
}

.img1 {
    width: 200px;
    padding-top: 8rem;
}

.img11 {
    width: 200px;
    padding-bottom: 8rem;
}

@media (max-width: 1200px) {
    .firstContainer {
        width: 90%;
        flex-direction: column;
        align-items: center;
        text-align: center;
    }

    .firstContainerText {
        width: 90%;
        align-items: center;
        margin-right: 0;
    }

    .firstContainerImage {
        align-items: center;
    }

    .img1 {
        padding-top: 1rem;
        height: 300px;
        width: auto;
    }

    .img11 {
        padding-bottom: 0;
        padding-top: 1rem;
        height: 300px;
        width: auto;
    }
}

@media (max-width: 700px) {
    .firstContainer {
        width: 90%;
        flex-direction: column;
        align-items: center;
        text-align: center;
    }

    .firstContainerText {
        width: 90%;
        align-items: center;
        margin-right: 0;
    }

    .firstContainerImage {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .img1 {
        padding-top: 1rem;
    }

    .img11 {
        padding: 0;
    }
}

/*------WORKSHOP DESCRIPTION CONTAINER--------*/

.workshopDescriptionsContainer {
    width: 100%;
    background-color: rgb(232,230,219);
    margin-top: 2rem;
    margin-bottom: 2rem;
    padding-bottom: 2rem;
    padding-top: 3rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: "Montserrat", sans-serif;
    letter-spacing: -0.4px;
}

.subContainerTitle {
    display: flex;
    align-items: center;
    margin: 2rem;
}

.subContainers {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.subContainer1 {
    width: 80%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    gap: 4rem;
    margin-bottom: 2rem;
}

.subContainer {
    width: 50%;
}

.title {
    font-size: 20px;
    margin-bottom: 1rem;
}

.subtitle {
    font-size: 20px;
    margin: 1rem;
}

.text2 {
    line-height: 1.5;
    text-align: center;
    letter-spacing: 1px;
    padding: 10px;
}

@media (max-width: 800px) {
    .subContainers {
        flex-direction: column;
    }

    .subContainer1 {
        width: 100%;
        flex-direction: column;
        align-items: center;
    }

    .subContainer {
        width: 90%;
    }

    .text2 {
        text-align: justify;
    }
}


/*------IMAGE CONTAINER-------*/

.imageContainer {
    width: 100%;
    margin-top: 2rem;
    margin-bottom: 2rem;
}

.img2 {
    width: 300px;
    margin:2rem;
}

.img3 {
    width: 400px;
    margin:2rem;
}

.img4 {
    width: 300px;
    margin:2rem;
}

@media (max-width: 1200px) {
    .img3 {
        width: 300px;
    }
}


/*------WORKSHOP PRICE CONTAINER--------*/

.priceTextContainer {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    margin: 2rem;
    padding: 2rem;
    background-color: rgb(232,230,219);
    align-items: center;
}

.priceSubtitle {
    font-size: 2rem;
    font-family: 'Cinzel', serif;
    margin-bottom: 1rem;
}

.priceText {
    font-size: 1rem;
    margin: 0.2rem;
}

/*-------------ADDITIONAL TEXT--------------*/

.workshopContentContainer {
    width: 100%;
    height: auto;
    margin-top: 5rem;
    height: 70vh;
}

.designElement {
    width: 80%;
    height: auto;
    display:flex;
    justify-content: flex-end;
    margin-left: auto;
    padding-top: 2rem;
    padding-bottom: 2rem;
    background-color: rgb(232,230,219);
}

.img5 {
    height: 550px;
    position: absolute;
    left: 8%;
    margin-top: 2rem;
    border: 1rem solid rgb(232,230,219);
}

.workshopText {
    display: flex;
    width: 70%;
    flex-direction: column;
    align-items: flex-start;
    margin-right: 4rem;
}

.text3 {
    padding: 5px;
    text-align: justify;
}

.text4 {
    padding-left: 5px;
    letter-spacing: 0.1px;
}

@media (max-width: 1400px) {
    .workshopText {
        width: 65%;
    }
    
    .img5 {
        height: 400px;
        left: 5%;
    }
}

@media (max-width: 1300px) {
    .workshopContentContainer {
        height: auto;
    }

    .img5 {
        margin-top: 1rem;
    }
}

@media (max-width: 1100px) {
    .workshopContentContainer {
        height: auto;
    }

    .img5 {
        height: 300px;
        margin-top: 5rem;
    }
}

@media (max-width: 1000px) {
    .workshopContentContainer {
        height: 45vh;
    }

    .img5 {
        margin-top: 8rem;
    }
}

@media (max-width: 920px) {
    .workshopContentContainer {
        height: auto;
    }

    .img5 {
        margin-top: 6rem;
    }
}

@media (max-width: 800px) {
    .workshopContentContainer {
        height: auto;
    }

    .designElement {
        width: 100%;
    }

    .img5 {
        display: none;
    }

    .workshopText {
        width: 90%;
        margin: 0 auto;
    }
}
/*----------------- APPLY ---------------*/

.applyBox {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin: 3rem;
}

.buttonBoxText {
    padding-bottom: 2rem;
    font-size: 1rem;
}

.buttonBox {
    width: 100%;
    display: flex;
    justify-content: center;
}

/*------- BOX --------*/

.addressContainer {
    width: 100%;
    height: 20vh;
    background-color:  rgb(232,230,219);
    margin-top: 2rem;
    margin-bottom: 2rem;
    display: flex;
    flex-direction: row;
}

.addressBox {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 50%;
}

/*-----------------IMGAGES-----------------*/

.galleryTitle {
    align-items: center;
    font-family: 'Cinzel', serif;
    margin-top: 2rem;
    margin-bottom: 1rem;
    font-size: 2rem;
}

.imageContainer2 {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
    gap: 3px;
    margin: 2rem;
}

.images {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

@media (max-width: 1400px) {
    .imageContainer2 {
        grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    }
}